import ContactPage from "./help/_contactPage";

export default ContactPage

export const frontmatter = {
  pageMetaData: {
    refKey: "contact",
    title: "Contact the team behind SimpleBackups",
    description: "Reach the team behind SimpleBackups by email or chat! We place the highest importance to awesome customer support, and our team will be available to respond to any of your questions.",
  }
}