import React from "react"
import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { Section, Typography, Container, Row, SectionTitle, Link } from "components/ui"
import ContactForm from './_contactForm'

const ContactPage = () => {
    return (
      <Layout>
        <SEO refKey="contact" />
        <Section color="alt">
          <Container>
            <Row>
              <div className="col-md-12 ta-c">
                <SectionTitle
                  tag="h1"
                  align="center"
                  title={(<>Let's talk!</>)}
                  subTitle={(<>Have any questions? We would love to hear from you.</>)}
                />
              </div>
            </Row>
          </Container>
        </Section>

        <Section padding>
          <Container>
            <Row>
              <div className="lg:grid grid-cols-12 gap-20">
                <div className="lg:col-span-6">
                  <Typography tag="h3">
                    Contact us
                  </Typography>
                  <Typography tag="p">
                    We place high importance on customer support, our team will be available to respond to any of your questions.
                  </Typography>
                  <Typography tag="p">
                    Email: <strong>hello@simplebackups.com</strong>
                    {/*Phone: <strong>262-696-9826</strong>*/}
                  </Typography>
                  <Typography tag="p">
                    We're also reachable during working hours via our chat system that you can trigger by clicking on
                    the vignette at the bottom of your screen.
                  </Typography>
                  <Typography tag="p">
                    You can also <Link to="https://calendly.com/simplebackups" rel="nofollow noopener noreferrer" target="_blank">schedule a demo</Link>.
                  </Typography>
                </div>
                <div className="flex col-span-6 items-center justify-center align-center">
                  <div className="grid grid-cols-2 gap-8">
                    <a href="mailto:hello@simplebackups.com?subject=SimpleBackups Website Contact Request&body=Hey there," target="_blank" className="p-16 bg-gray-200 flex items-center justify-center flex-col gap-2 rounded-2xl hover:no-underline">
                      <i className="fa fa-envelope"></i>
                      <p className="uppercase text-sm tracking-wide">Email</p>
                    </a>
                    <div className="p-16 bg-gray-200 flex items-center justify-center flex-col gap-2 rounded-2xl" onClick={() => window.Intercom('showNewMessage')}>
                      <i className="fa fa-comment"></i>
                      <p className="uppercase text-sm tracking-wide">Chat with us</p>
                    </div>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
        </Section>

      </Layout>
    )
}
export default ContactPage
